.sidebar__reviews {
    background-color: #fff;
    width: 100%;
    max-width: 100%;
    border-bottom: 1px solid #DFDFDF;

    .reviews__block {
        border-top: 1px solid #EAEAEA;

        &:last-child {
            border-bottom: 1px solid #EAEAEA;
        }

        .rating {
            height: max-content;
        }

        .info {
            p:last-child {
                color: #888888;
            }
        }
    }

    .Slider_review_images{
        max-height: 130px !important;
        max-width: 100px;
        cursor: pointer;
    }

    .review_images_container{
        display: flex;
        gap: 15px;
         overflow: auto; 
        -ms-overflow-style: none; 
        scrollbar-width: none; 
    }

    .review_images_container::-webkit-scrollbar {
        width: 0px;
        height: 0px;
    }
}
