.header__review__sidebar {
    .body{
        width: 100%;
    }
     .sidebar__review {
         background-color: #fff;
         width: 100%;
         max-width: 100%;

         form {
             border-bottom: 1px solid #DFDFDF;
             .MuiRating-root{
                .MuiRating-icon {
                    svg{
                        width: 45px;
                        height: 45px;
                    }
                }
             }
             textarea{
                height: 110px;
                resize: none;
            }
         }

         .action__block {
             svg {
                 width: 20px;
             }
             button{
                height: 40px;
                width: 157px;
                &:hover{
                    background-color: #4D4D4D !important;
                }
             }
         }
     }

     .upload-container {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
        gap: 10px;
        padding: 10px;
      }
      
      .image-wrapper {
        position: relative;
        width: 100px;
        height: 100px;
        border-radius: 8px;
        overflow: hidden;
      }
      
      .image-preview {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
      }
      
      .close-btn {
        position: absolute;
        top: 8px;
        right: 5px;
        background: white;
        color: #aaa;
        border: none;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        cursor: pointer;
      }
      
      .upload-box {
        width: 100px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 32px;
        color: #aaa;
        background: #f5f5f5;
        border-radius: 8px;
        border: 4px dashed #ddd;
        cursor: pointer;
      }
      
      .upload-box input {
        display: none;
      }
      
 }
