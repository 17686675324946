/* slick slider */
.slick-nogap {
  .slick-list {
    margin: 0;
    padding-left: inherit !important;
    width: 100%;
    overflow: hidden;
    .slick-track {
      width: 100%;
      .slick-slide {
        width: 100%;
        >div {
          margin: 0;
        }
      }
    }
  }
}

.pdp_quantity_popup{
  .body{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) !important;
      background-color: #fff;
      padding: 20px;
      border-radius: 5px;
      max-width: 80% !important; 
      height: auto; 
     
      border: 1px solid #EAEAEA;

  }
}
.slick-list {
  margin: 0 -10px;
  padding-left: 0px !important;

  .slick-track {
    display: flex;
    flex-wrap: nowrap;

    .slick-slide {
      >div {
        margin: 0 10px;
      }
    }
  }
}

.primary__btn {
  background-color: #222222;
  border-radius: 35px;
  color: #ffffff;
  font-size: 15px;
  width: 156px;
  padding: 1rem 0.1rem;
  text-align: center;

  &:hover {
    background-color: var(--themeColor);
  }
}

// text ellipse
.text__ellipse {
  min-height: 25px;
  overflow: hidden;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: normal;
  width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

// error message
.error {
  color: #e44061;
}

.success {
  color: #43A700;
}

// rotate icon
.rotateUpdate {
  svg {
    animation: rotateUpdate 0.7s infinite;
    width: 21px;
    height: 21px;

    @keyframes rotateUpdate {
      from {
        transform: rotate(0deg);
      }

      to {
        transform: rotate(360deg);
      }
    }
  }
}

.hover__underline {
  &:hover {
    text-decoration: underline;
  }
}

// select dropdown design
.MuiMenu-paper {
  transition-duration: 0s !important;
}

.MuiMenu-root {
  .MuiPaper-root {
    box-shadow: unset;
    border-radius: 0px;
    border: 1px solid #dfdfdf;
    border-top: 0px;

    .MuiList-root {
      padding: 0px;

      .MuiButtonBase-root {
        font-family: "poppins";
        padding-top: 4px;
        padding-bottom: 4px;
        font-size: 14px;

        &.Mui-selected {
          background-color: var(--themeColor);
          color: #ffffff;
        }
      }
    }
  }
}

// snackbar
.MuiSnackbar-root {
  max-width: 90%;
  margin-right: 0px;
  margin-left: auto;
  width: max-content;
  right: 0 !important;
  left: auto !important;
  right: 16px !important;
  left: 16px !important;
  top: 16px !important;

  .MuiPaper-root {
    background-color: #222222;

    .MuiSnackbarContent-message {
      font-family: "poppins";
      font-size: 15px;
    }
  }
}

// sticky header shadow
header.headerShadow {
  -webkit-box-shadow: 0px 0px 4px rgba(50, 50, 50, 0.2);
  box-shadow: 0px 0px 4px rgba(50, 50, 50, 0.2);
}

header.headerBorder {
  // border-top: 1px solid rgba(50, 50, 50, 0.2);
}

// static pages table
.static__table {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  overflow-x: auto;

  table {
    border: 0px;

    th,
    th strong {
      font-weight: 700;
      font-size: 18px;
    }

    td,
    th {
      font-size: 15px;
      line-height: 36px;
      padding: 8px 20px;
      white-space: nowrap;
      min-width: 177px;
      border: 0px solid #EAEAEA;

      &:first-child {
        width: 240px;
        min-width: 240px;
      }
    }

    tbody {
      tr {
        border-top: 1px solid #EAEAEA;

        .flex {
          .image {
            width: 47px;
            height: 47px;
            min-width: 47px;
            min-height: 47px;
          }
        }

        &:nth-child(odd) {
          background-color: #FAFAFA;
        }

        &:last-child {
          border-bottom: 1px solid #EAEAEA;
        }
      }
    }
  }
}

// sidebar width adjustment common
@media(min-width: 551px) {
  .modelBox {
    .body {
      max-width: 500px;
      min-width: 500px;
    }
  }
 

  .search__suggestion__container {
    .modelBox {
      .body {
        max-width: 100%;
        min-width: 100%;
      }
    }
  }
}


@media (max-width:768px){
  
  .tawk-min-container{
    display: none !important;

  }
  .pdp_quantity_popup{
    .body{
       
        overflow-y: auto; 
        overflow-x: auto; 
  
    }
  }
}
@media(max-width: 550px) {
  .modelBox {
    & .body {
      max-width: calc(100% - 58px);
      min-width: calc(100% - 58px);
    }
  }

  .search__suggestion__container {
    & .modelBox {
      & .body {
        max-width: 100%;
        min-width: 100%;
      }
    }
  }
}